// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUurEHTRaC4PM5tjrCeajnodilfDWQ0yc",
  authDomain: "camper-d01e5.firebaseapp.com",
  projectId: "camper-d01e5",
  storageBucket: "camper-d01e5.appspot.com",
  messagingSenderId: "434690180257",
  appId: "1:434690180257:web:5a47530e422ad79d381ce0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);